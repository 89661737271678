<template>
  <a-modal
    title="新建"
    :width="880"
    :visible="visible"
    :confirmLoading="loading"
    @ok="() => { $emit('ok',this.map) }"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form">
        <a-form-item label="地图" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <Mapview @receive="mapData" :model="this.model"></Mapview>
        </a-form-item>
        <a-form-item label="地图地址" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input disabled v-decorator="['map_address', {rules: [{required: true, message: '请点击上面地图选择'}]}]"/>
        </a-form-item>
        <a-form-item label="姓名" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
            v-decorator="['receiving_name', {rules: [{ required: true, message: '请输入' }]}]" />
        </a-form-item>
        <a-form-item label="手机号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
            v-decorator="['receiving_phone', {rules: [{ required: true, message: '请输入正确的手机号', pattern: /^1[3456789]\d{9}$/ }]}]" />
        </a-form-item>
        <a-form-item label="自提点详址" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['address_detail', {rules: [{required: true, message: '请输入'}]}]" />
        </a-form-item>
        <a-form-item label="编码" :labelCol="labelCol" :wrapperCol="wrapperCol" v-show="false">
          <a-input v-decorator="['area_code', {rules: [{required: false, message: '请输入'}]}]" />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>
<script>
import pick from 'lodash.pick'

const fields = ['address_detail', 'receiving_phone', 'receiving_name', 'map_address', 'area_code']
export default {
  name: 'DeliverAddressForm',
  components: {},
  props: {
    model: {
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      map: {}
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.model !== null) {
        this.loadEditInfo(this.model)
      }
    })
  },
  methods: {
    loadEditInfo (data) {
      const { form } = this
      data.map_address = data.province + ',' + data.city + ',' + data.county
      const formData = pick(data, fields)
      console.log('formData', formData, data)
      form.setFieldsValue(formData)
      console.log('------------->>>>', data)
      this.map.province = data.province
      this.map.city = data.city
      this.map.county = data.county
      this.map.adcode = data.area_code
      this.map.latitude = data.area_code.split(',')[0]
      this.map.longitude = data.area_code.split(',')[1]
      if (!this.map.city) {
        this.map.city = this.map.province
      }
      this.map.map_address = data.map_address
    },
    mapData (data) {
      const province = data.regeocode.addressComponent.province
      const city = data.regeocode.addressComponent.city === '' ? province : data.regeocode.addressComponent.city
      const district = data.regeocode.addressComponent.district
      // const adcode = data.regeocode.addressComponent.adcode
      this.map.province = province
      this.map.city = city
      this.map.county = district
      this.map.adcode = data.latitude.toFixed(4) + ',' + data.longitude.toFixed(4)
      this.map.latitude = data.latitude
      this.map.longitude = data.longitude
      if (this.map.city.length === 0) {
        this.map.city = this.map.province
      }
      const map_address = this.map.province + ',' + this.map.city + ',' + this.map.county
      const { form } = this
      this.$nextTick(() => {
        setTimeout(() => {
          const data = { map_address: map_address, area_code: this.map.adcode }
          console.log(data)
          const formData = pick(data, 'map_address', 'area_code')
          form.setFieldsValue(formData)
        })
      })
    }
  }
}
</script>
<style>
</style>
