<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <div class="table-operator">
                <a-button type="primary" icon="plus" @click="handleCreate">添加</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">编辑</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>
    </a-card>
    <create-refund-address-form
      ref="createModal"
      v-if="visible"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import { createRefundAddress, putRefundAddress, deleteRefundAddress, refundAddressList } from '@/api/applet_shopmall_order'
import CreateRefundAddressForm from '@/views/a-applet/shop_manage/deliveryaddress/CreateRefundAddressForm'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateRefundAddressForm
  },
  data () {
    return {
      loading: false,
      visible: false,
      confirmLoading: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '省市区',
          ellipsis: true,
          dataIndex: 'province',
          customRender: (text, record, index) => record.province + ',' + record.city + ',' + record.county
        },
        {
          title: '详细地址',
          ellipsis: true,
          dataIndex: 'address_detail'
        },
        {
          title: '接收人',
          ellipsis: true,
          dataIndex: 'receiving_name'
        },
        {
          title: '电话',
          ellipsis: true,
          dataIndex: 'receiving_phone'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return refundAddressList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return data
          })
      }
    }
  },
  methods: {
    handleCreate () {
      this.mdl = null
      this.visible = true
      this.confirmLoading = false
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk (data) {
      console.log(data)
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          if (this.mdl && this.mdl.id) {
            const createData = { ...values, ...data }
            putRefundAddress(createData, this.mdl.id).then((res) => {
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            })
          } else {
            console.log('-----', values)
            console.log('=====', data)
            const createData = { ...values, ...data, area_code: data.adcode }
            console.log('提交的数据----', createData)
            createRefundAddress(createData).then((res) => {
              this.visible = false
              this.confirmLoading = false
              this.$refs.table.refresh()
            })
          }
        } else {
          this.confirmLoading = false
        }
        this.mdl = {}
      })
    },
    handleDelete (item) {
      console.log(item)
      deleteRefundAddress(item.id).then((res) => {
        console.log(res, '删除成功')
        this.$refs.table.refresh()
      })
    },
    handleEdit (item) {
      console.log(item)
      this.mdl = item
      this.visible = true
      this.confirmLoading = false
      this.mdl.latitude = item.area_code.split(',')[0]
      this.mdl.longitude = item.area_code.split(',')[1]
    }
  }
}
</script>
